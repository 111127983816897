<template>
  <div>
    <catalog-loader v-if="loaderAvailableTemplate" />
    <div class="row select-templates" v-else>
      <div class="heading-templates">
        {{ $t("templates.available-templates") }}
        <hcc-select
          v-model="selectedType"
          track-by="id"
          option-label="name"
          :placeholder="$t('templates.select-type')"
          :options="options"
          :disabled="!items.length"
          customClass="select"
        />
      </div>

      <div class="columns shrink sidebar templates-choice">
        <p>{{ $t("templates.choose-template") }}</p>
        <div
          v-if="filteredItems && filteredItems.length"
          class="grid-x grid-margin-x templates-wrapper"
        >
          <template v-for="template in filteredItems">
            <div
              @click="setTemplateSelected(template)"
              :class="{
                active: templateSelected && templateSelected.id === template.id,
              }"
              class="template-card large-4"
              :key="template.id"
            >
              <div class="element-name">
                element_name: {{template.element_name}}
              </div>
              {{ template.text }}
            </div>
          </template>
        </div>
        <div
          v-else
          class="grid-x grid-margin-x templates-wrapper templates-empty"
        >
          {{ $t("templates.no-template") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import CatalogLoader from '@/components/loaders/Catalog.vue';
import HccSelect from '@/components/shared/HccSelect/index.vue';


export default {
  components: {
    CatalogLoader,
    HccSelect,
  },
  props: {
    items: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      options: [{
        id: 1,
        key: 'text',
        name: this.$t('templates.text'),
      },
      {
        id: 2,
        key: 'image',
        name: this.$t('templates.image'),
      },
      {
        id: 3,
        key: 'video',
        name: this.$t('templates.video'),
      },
      {
        id: 4,
        key: 'document',
        name: this.$t('templates.document'),
      },
      {
        id: 5,
        key: '',
        name: this.$t('templates.all'),
      },
      ],
      selectedType: null,
      filteredItems: [],
    };
  },
  watch: {
    items(newVal) {
      if (newVal.length > 0) {
        this.selectedType = {
          id: 5,
          name: this.$t('templates.all'),
        };
      }
      this.setTemplateSelected(null);
      this.filteredItems = this.items;
    },
    selectedType(newVal) {
      if (newVal.name !== this.$t('templates.all')) {
        const type = newVal.key === 'image' ? this.items
          .filter(({ settings }) => ['image', 'tImage'].includes(settings.message_type)) : this.items
          .filter(({ settings }) => settings.message_type === newVal.key);

        this.filteredItems = type;
      } else {
        this.filteredItems = this.items;
      }
    },
  },
  computed: {
    ...mapState({
      loaderAvailableTemplate: state => state.template.loaderAvailableTemplate,
      templateSelected: state => state.template.templateSelected,
    }),

  },
  methods: {
    ...mapMutations({
      setTemplateSelected: 'template/setTemplateSelected',
    }),
  },
  mounted() {
    this.filteredItems = this.items;
  },
};
</script>
