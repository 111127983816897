<template>
  <modal>
    <div slot="header" class="header-details">
      <h1 class="small-11 columns full-name">{{ item.fullName }}</h1>
      <button class="small-1 columns close-modal" type="button" @click="closeModal">
        <span aria-hidden="true" aria-label="Close modal">&times;</span>
      </button>
    </div>
    <div slot="body">
      <div class="modal-info">
        <h2 class="heading heading-2 margin-bottom--20">
          {{ sendTemplatesModal.lastConversation }}:
        </h2>
        <p class="paragraph margin-bottom--20">{{ item.updatedAt }}</p>
      </div>

      <div class="modal-info">
        <h2 class="heading heading-2 margin-bottom--20">
          {{ sendTemplatesModal.number }}:
        </h2>
        <p class="paragraph margin-bottom--20">{{ item.username }}</p>
      </div>

      <div class="modal-info container-labels">
        <h2 class="heading heading-2 margin-bottom--20">
          {{ sendTemplatesModal.labels }}:
        </h2>
      </div>
      <div class="modal-labes">
        <div class="labels" v-for="(label, i) in item.labels" :key="i">
          <span v-tooltip="label.value" class="">{{ label.key }}</span>
        </div>
      </div>
    </div>
    <div slot="footer" aria-hidden="true" class="container-add">
      <button @click="closeModal(item)" class="button add-contact">
        {{ sendTemplatesModal.close }}
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sendTemplatesModal: {
        close: this.$t('filter.close'),
        number: this.$t('app.send-templates-modal.number'),
        name: this.$t('common.name'),
        lastConversation: this.$t('app.send-templates-modal.last-conversation'),
        labels: this.$t('app.send-templates-modal.labels'),
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_modal-template.scss';
</style>
