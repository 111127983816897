<template>
  <div>
    <div class="labels">
      <hcc-button-icon @click="showLabels = !showLabels" class="labels__button">
        <dots-horizontal-circle-icon />
      </hcc-button-icon>
      <transition name="fade">
        <div v-if="showLabels" class="labels__options-container">
          <hcc-select
            trackBy="id"
            optionLabel="label"
            :options="labels"
            :value="selectedLabel"
            @select="selectLabel"
            class="labels__options"
            customClass="select"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import HccSelect from '@/components/shared/HccSelect/index.vue';
import HccButtonIcon from '@/components/shared/HccButtonIcon/index.vue';

export default {
  props: {
    labels: {
      type: Array,
    },
    index: {
      type: Number,
    },
  },
  components: {
    HccSelect,
    HccButtonIcon,
  },
  data() {
    return {
      selectedLabel: null,
      showLabels: false,
    };
  },
  methods: {
    selectLabel(item) {
      this.$emit('select-label', { label: item.label, index: this.index });
      this.showLabels = false;
    },
  },

};
</script>


<style scoped lang="scss">
@import "~styles/views/_sendTemplates.scss";
</style>
