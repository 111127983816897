<template>
  <div>
    <modal-template
      v-if="client"
      :item="client"
      @close="closeModal"
    />
    <hcc-checkbox-table
      ref="clientTable"
      @search="search"
      :pagination="pagination.totalPages > 5"
      :emptyRowsTable="emptyRows"
      :columns="columns"
      :rows="rows"
      @pageChanged="changePage"
      @preview="moreInfo"
      :rowsPerPage="pagination.perPage"
      :actualPage="pagination.page"
      :totalPages="pagination.totalPages"
      @checked-row="triggerCheckbox"
      @check-all="selectAll"
      @uncheck-all="selectAll"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import HccCheckboxTable from '@/components/shared/HccTable/HccCheckboxTable.vue';
import ModalTemplate from '@/components/ModalTemplate.vue';

export default {
  props: {
    rows: {
      require: true,
      type: Array,
      default: () => [],
    },
    pagination: {
      require: true,
      type: Object,
    },
  },
  components: {
    HccCheckboxTable,
    ModalTemplate,
  },
  data() {
    return {
      client: null,
    };
  },
  computed: {
    ...mapState({ clientList: state => state.template.clientList }),
    columns() {
      return [{
        label: this.$t('templates.table.headers.number'),
        field: 'username',
      }, {
        label: this.$t('common.name'),
        field: 'fullName',
      }, {
        label: this.$t('templates.table.headers.lastconection'),
        field: 'updatedAt',
      }, {
        label: this.$t('templates.table.headers.tags'),
        field: 'labels',
      }];
    },
    emptyRows() {
      return this.pagination.totalPages === 0;
    },
  },
  watch: {
    clientList(newVal) {
      if (newVal.length === 0) {
        this.$refs.clientTable.clearChecked();
      }
    },
  },
  methods: {
    ...mapMutations({
      setClientList: 'template/setClientList',
      deleteClientList: 'template/deleteClientList',
    }),
    moreInfo(props) {
      this.$nextTick(() => {
        this.client = props;
      });
    },
    closeModal() {
      this.client = null;
    },
    triggerCheckbox(item) {
      if (item.checked) {
        this.addClient(item);
      } else {
        this.deleteClientList(item.id);
      }
    },
    addClient(item) {
      const index = this.clientList.findIndex(select => select.id === item.id);
      if (index === -1) {
        const add = this.clientList;
        add.push({ ...item, params: [] });
        this.setClientList(add);
      }
    },
    changePage(page) {
      this.$emit('changePage', page);
    },
    search(value) {
      if (value.length >= 3) {
        this.$emit('search', value);
      } else if (value.length === 0) {
        this.$emit('search', '');
      }
    },
    selectAll(event) {
      this.$emit('select-all-rows', event);
    },
  },
};

</script>

<style scoped lang="scss">
.no-label {
  font-weight: bold;
  color: $gray-disabled;
}
</style>
